import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';

import { Common } from '../../services/common.service';

import { Configuration } from '../../constants';
import { FunctionService } from 'src/app/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-glc-functions',
  templateUrl: './functions.component.html',
  styleUrls: ['./functions.component.scss']
})
export class FunctionsComponent implements OnInit, AfterViewInit {
  functionRoute = Configuration.ROUTE_PATH.FUNCTIONS;
  deviceRoute = Configuration.ROUTE_PATH.DEVICES;
  pageDescription = 'FUNCTIONS_VIEW_DESCRIPTION';
  tableData: any = null;
  pageSize = Configuration.pageSize;
  pageOffset = 0;
  searchValue = '';
  sortValue = {
    active: '',
    direction: ''
  };

  fakeData = [{
    id: 1,
    image: '../../../assets/images/avatar.png',
    name: 'Peter Meiers',
    email: {
      title: 'E-Mail',
      text: ['beistpiel.bsp@e-mail.ch']
    },
    phone: {
      title: 'Telefon',
      text: ['+41 79 102 11 22']
    },
    address: {
      title: 'Adresse',
      text: ['Glatttalstrasse 118 F, 8052 Zürich']
    },
    wohnung: {
      title: 'Wohnungreferenz',
      text: ['Studio 1.4']
    }
  }, {
    id: 2,
    image: '../../../assets/images/avatar.png',
    name: 'Susan Reinart',
    email: {
      title: 'E-Mail',
      text: ['beistpiel.bsp@e-mail.ch']
    },
    phone: {
      title: 'Telefon',
      text: ['+41 79 102 11 22']
    },
    address: {
      title: 'Adresse',
      text: ['Glatttalstrasse 118 C, 8052 Zürich']
    },
    wohnung: {
      title: 'Wohnungreferenz',
      text: ['Wohnung 2.5']
    }
  }, {
    id: 3,
    image: '../../../assets/images/avatar.png',
    name: 'Robert Park',
    email: {
      title: 'E-Mail',
      text: ['beistpiel.bsp@e-mail.ch']
    },
    phone: {
      title: 'Telefon',
      text: ['+41 79 102 11 22']
    },
    address: {
      title: 'Adresse',
      text: ['Karstlernstrasse 12, 8048  Zürich']
    },
    wohnung: {
      title: 'Wohnungreferenz',
      text: ['Attika B1']
    }
  }];

  @ViewChild('content', { static: false }) content: ElementRef;

  constructor(
    private common: Common,
    private functionService: FunctionService,
    private modalService: NgbModal
  ) { }

  open(content) {
    this.modalService.open(content).result.then((result) => {
      console.log(result);
    }, (reason) => {
      console.log(reason);
    });
  }

  ngOnInit() {
    this.initData();
    this.getFunctionsList();
  }

  ngAfterViewInit() {
  }

  initData() {
    const displayedColumns = ['image', 'name', 'email', 'phone', 'address', 'wohnung', 'customEvent'];

    this.tableData = {
      displayedColumns,
      field: [
        { name: 'image', title: '', img: true, sortable: false },
        { name: 'name', title: 'Mieter' },
        { name: 'email', title: 'E-Mail', hasTitleFormat: true },
        { name: 'phone', title: 'Telefon', hasTitleFormat: true },
        { name: 'address', title: 'Adresse', hasTitleFormat: true },
        { name: 'wohnung', title: 'Wohnungreferenz', hasTitleFormat: true },
        { name: 'customEvent', title: 'Aktion', hasDropdownAction: true, sortable: false }
      ],
      pageSize: this.pageSize,
      totalRecords: 0,
      pageIndex: this.pageOffset,
      dataSource: []
    };
  }

  getFunctionsList() {
    const response = this.fakeData;
    const buildings = [];
    for (let i = 0; i < response.length; i++) {
      const item: any = response[i];
      item.customEvent = {
        text: 'Optionen',
        icon: 'angle-down',
        screenshot: '../../../assets/images/action-3.jpg',
        event: () => {

        }
      };
      item.selectedId = item.id;
      buildings.push(item);
    }

    this.tableData = {
      ...this.tableData,
      pageIndex: this.pageOffset,
      totalRecords: response.length,
      dataSource: buildings
    };
  }

  createNewData(id: number) {
    return {
      id,
      image: '../../../assets/images/avatar.png',
      name: 'Peter Meiers',
      email: {
        title: 'E-Mail',
        text: ['beistpiel.bsp@e-mail.ch']
      },
      phone: {
        title: 'Telefon',
        text: ['+41 79 102 11 22']
      },
      address: {
        title: 'Adresse',
        text: ['Glatttalstrasse 118 F, 8052 Zürich']
      },
      wohnung: {
        title: 'Wohnungreferenz',
        text: ['Studio 1.4']
      }
    };
  }

  mapFunctionData(data) {
    return {
      id: data.id,
      name: data.name,
      action: [
        {
          text: 'VIEW',
          icon: 'eye',
          url: `/${this.functionRoute}/${data.id}`
        },
        {
          text: 'ASSIGNED_DEVICES',
          icon: 'plug',
          url: `/${this.deviceRoute}/function/${data.id}`
        },
      ],
    };
  }

  applyFilter(_filterValue: string) {
    this.getFunctionsList();
  }

  pageChange(event) {
    this.pageOffset = event.pageIndex;
    this.applyFilter(this.searchValue);
  }

  sortChange(event) {
    this.sortValue = event;
    this.getFunctionsList();
  }

  add() {
  }
}
