<app-glc-page-content
  [pageTitle]="'Objektliste'"
  [pageDescription]="pageDescription"
>
  <app-glc-glsearch buttonText="Objekt hinzufügen" [(inputSearch)]="searchValue" (search)="applyFilter($event)"></app-glc-glsearch>
  <app-glc-table 
    class="myTable table-1"
    [(data)]="tableData"
    (page)="pageChange($event)" 
    (sort)="sortChange($event)"
  >
  </app-glc-table>
</app-glc-page-content>
