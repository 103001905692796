<header class="top-header">
  <div class="left-wrapper">
    <img src="assets/images/logo.png" alt="GlencoreLogo" width="144">
    <ul class="top-menu">
      <li>
        <a class="menu" routerLink="/{{routePath.BUILDINGS}}" routerLinkActive="active">
          <span class="menu-name">{{ 'Objektliste' | translate }}</span>
        </a>
      </li>
      <li>
        <a class="menu" routerLink="/{{routePath.FUNCTIONS}}" routerLinkActive="active">
          <span class="menu-name">{{ 'Mieter' | translate }}</span>
        </a>
      </li>
      <li>
        <a class="menu" routerLink="/{{routePath.DEVICES}}" routerLinkActive="active">
          <span class="menu-name">{{ 'Geräte' | translate }}</span>
        </a>
      </li>
      <li>
        <a class="menu" routerLink="/{{routePath.SERVICE_TEAMS}}" routerLinkActive="active">
          <span class="menu-name">{{ 'Serviceaufträge' | translate }} <span class="counter">3</span></span>
        </a>
      </li>
      <li>
        <a class="menu" routerLink="/{{routePath.USERS}}" routerLinkActive="active">
          <span class="menu-name">{{ 'Benutzerverwaltung' | translate }}</span>
        </a>
      </li>
    </ul>
  </div>
  <div class="right-wrapper">
    <div class="user-wrapper">
      <img src="assets/images/user-avatar.jpg" class="user-avatar img-circle" alt="User">
      <div class="user-info">
        <div class="name">
          Thomas Klauser
        </div>
        <div class="type">
          Immobilien AG Zürich
        </div>
      </div>
      <div class="dropdown cursor">
          <i class="fas fa-chevron-down" data-toggle="dropdown"></i>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
            <li><a href="#" (click)="logout()">Logout</a></li>
          </ul>
        </div>
    </div>
  </div>
</header>