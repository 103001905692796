import { Component, OnInit, AfterViewInit } from '@angular/core';

import { UserService } from '../../services';
import { Common } from '../../services/common.service';
import { Configuration, USER_ROLE, TOASTER_TYPE, CONFIRM_MODAL_OPTIONS, TABLE_SETTINGS } from '../../constants';
import { Credential } from '../../models';

@Component({
  selector: 'app-glc-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, AfterViewInit {
  private credentials: Credential;
  isAdmin = false;
  userRoute = Configuration.ROUTE_PATH.USERS;
  pageDescription = 'USERS_VIEW_DESCRIPTION';

  tableData: any = null;
  isShowMultipleSelect = false;
  pageSize = Configuration.pageSize;
  pageOffset = 0;
  searchValue = '';
  sortValue = {
    active: '',
    direction: ''
  };

  fakeData = [{
    id: 1,
    name: 'Hans Mustermann',
    email: 'userMail@e-mail.ch',
    role: 'Standard user',
    active: 'Aktiv'
  }, {
    id: 2,
    name: 'Hans Mustermann',
    email: 'userMail@e-mail.ch',
    role: 'Süper user',
    active: 'Aktiv'
  }, {
    id: 3,
    name: 'Hans Mustermann',
    email: 'userMail@e-mail.ch',
    role: 'Administrator',
    active: 'Aktiv'
  }, {
    id: 4,
    name: 'Hans Mustermann',
    email: 'userMail@e-mail.ch',
    role: 'Standard user',
    active: 'Inaktiv'
  }, {
    id: 5,
    name: 'Hans Mustermann',
    email: 'userMail@e-mail.ch',
    role: 'Standard user',
    active: 'Aktiv'
  }, {
    id: 6,
    name: 'Hans Mustermann',
    email: 'userMail@e-mail.ch',
    role: 'Standard user',
    active: 'Inaktiv'
  }];

  constructor(
    private common: Common,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.initData();
    this.getUserList();
  }

  ngAfterViewInit() {
  }

  getUserList() {
    const response = this.fakeData;
    const buildings = [];
    for (let i = 0; i < response.length; i++) {
      const item: any = response[i];
      item.action = [{
        text: 'Details',
        icon: 'pencil-alt',
        url: '/#'
      }];

      item.selectedId = item.id;
      buildings.push(item);
    }

    this.tableData = {
      ...this.tableData,
      pageIndex: this.pageOffset,
      totalRecords: response.length,
      dataSource: buildings
    };
  }

  initData() {
    const displayedColumns = ['name', 'email', 'role', 'active', 'action'];

    this.tableData = {
      displayedColumns,
      field: [
        {
          name: 'name',
          title: 'Name'
        },
        {
          name: 'email',
          title: 'E-Mail'
        },
        {
          name: 'role',
          title: 'Rolle'
        },
        {
          name: 'active',
          title: 'Aktiv/Inaktiv'
        },
        { name: 'action', title: 'Optionen', link: true, sortable: false }
      ],
      pageSize: this.pageSize,
      totalRecords: 0,
      pageIndex: this.pageOffset,
      dataSource: []
    };
  }

  createNewData(id: number) {
    return {
      id,
      name: 'Hans Mustermann',
      email: 'userMail@e-mail.ch',
      role: 'Standard user',
      active: 'Aktiv'
    };
  }

  mapUserData(user) {
    return {
      selectedId: user.id,
      id: user.id,
      name: user.name,
      email: user.email,
      role: user.role_id,
      roleText: user.role_id === USER_ROLE.ADMINISTRATOR ? 'ADMINISTRATOR' : user.role_id === USER_ROLE.SUPER_USER ? 'SUPER_USER' : 'STANDARD_USER',
      active: user.is_active,
      activeText: user.is_active ? 'ACTIVE' : 'INACTIVE',
      action: [
        {
          text: 'VIEW',
          icon: 'eye',
          url: `/${this.userRoute}/${user.id}`
        }
      ],
      selected: false
    };
  }

  applyFilter(_filterValue: string) {
    this.getUserList();
  }

  pageChange(event) {
    this.pageOffset = event.pageIndex;
    this.applyFilter(this.searchValue);
  }

  sortChange(event) {
    this.sortValue = event;
    switch (event.active) {
      case 'roleText':
        this.sortValue.active = 'role_id';
        break;
      case 'activeText':
        this.sortValue.active = 'is_active';
        break;
    }
    this.getUserList();
  }

  add() {
    if (this.isAdmin) {
      this.common.navigate(`${this.userRoute}/create`);
    }
  }

  deleteUsers(listIds) {
    const userId = this.credentials.id;
    if (listIds.indexOf(userId) !== -1) {
      this.common.showError([this.common.translate('ERR_CANNOT_DELETE_SELF')]);
      return;
    }
    this.common.showConfirm(
      this.common.translate('CONFIRM'),
      this.common.translate('CONFIRM_DELETE_USER'),
      () => {
        this.common.showLoader();
        try {
          this.userService.delete({
            ids: listIds
          })
            .subscribe(
              (response: any) => {
                console.log(response);
                this.common.showToaster(
                  TOASTER_TYPE.success,
                  this.common.translate('SUCCESS'),
                  this.common.translate('DELETE_SUCCESSFUL')
                );
                this.isShowMultipleSelect = false;
                this.getUserList();
              });
        } catch (error) {
          this.common.hideLoader();
          this.common.showError([JSON.stringify(error)]);
        }
      }, null, CONFIRM_MODAL_OPTIONS.TYPE.RED
    );
  }
}
