<div class="main">
  <div class="cols-xs-12 col-sm-8 col-md-8 col-lg-8 main background-image">
  </div>
  <div class="cols-xs-12 col-sm-4 col-md-4 col-lg-4 main">
    <div class="login-form">
      <div class="">
        <img src="assets/images/logo.png" class="login-logo" alt="GlencoreLogo">
      </div>
      <div class="sign-in">
        <h3 class="page-title ">{{ 'LOGIN' | translate }}</h3>
        <form>
          <app-glc-input
            isRequired
            isHideRequireMarker="true"
            [title]="'EMAIL'"
            [placeholder]="'EMAIL'"
            [(value)]="loginForm.email"
            [(error)]="errors.email"
            (modelChangeEvent)="isEmailValid($event)"
            [name]="'email'"
          ></app-glc-input>
          <div class="password-container" style="display: none">
            <img src="assets/images/continue_login.png" class="register-logo" alt="continue_login">
            <app-glc-input
              isRequired
              isHideRequireMarker="true"
              [title]="'PASSWORD'"
              [placeholder]="'PASSWORD'"
              [type]="'password'"
              [(value)]="loginForm.password"
              [(error)]="errors.password"
              (modelChangeEvent)="isPasswordValid($event)"
              (enter)="login()"
              [name]="'password'"
            ></app-glc-input>
            <app-glc-checkbox
              [name]="'isRemember'"
              [text]="'REMEMBER_ME'"
              [(value)]="loginForm.isRemember"
              [labelPosition]="'after'"
            >
            </app-glc-checkbox>
          </div>
          <div class="login-btn-container">
            <div class="weiter-container">
              <button
                mat-button
                class="btn btn-block btn-primary"
                [disabled]="isDisabledWeiter()"
                (click)="showPassword()"
              >
                Weiter
              </button>
            </div>
            <div class="password-container" style="display: none">
              <button
                mat-button
                class="btn btn-block btn-primary"
                [disabled]="isDisabledBtn()"
                (click)="login()"
              >
                {{ 'LOGIN' | translate }}
              </button>
            </div>
          </div>
          <div class="back-to-login text-center">
            <a (click)="showForgotPassword()">{{ 'FORGOT_PASSWORD' | translate }}</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>