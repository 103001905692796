<!-- Menu -->
<app-menu></app-menu>
<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="page-header">
    <div class="page-title-container text-center">
      {{ pageTitle | translate }}
    </div>
  </div>
</section>

<!-- Main content -->
<section class="content">
  <ng-content></ng-content>
</section>
<!-- /.content -->
