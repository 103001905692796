<app-glc-page-content [pageTitle]="'Serviceaufträge'">
  <div class="block-page-header">
    <ul class="menu-style-1">
      <li>
        <a class="menu active">
          <span class="menu-name">{{ 'Laufende Aufträge' | translate }}</span>
        </a>
      </li>
      <li>
        <a class="menu">
          <span class="menu-name">{{ 'Abgeschlossene Aufträge' | translate }}</span>
        </a>
      </li>
    </ul>
  </div>
  <app-glc-glsearch [(inputSearch)]="searchValue" (search)="applyFilter($event)"></app-glc-glsearch>
  <app-glc-table class="myTable table-3" [(data)]="tableData" (page)="pageChange($event)" (sort)="sortChange($event)">
  </app-glc-table>
</app-glc-page-content>

<ng-template #content let-modal>
  <div class="modal-header">
    <span class="modal-title" id="modal-basic-title">SteamCrisp funktioniert nicht</span>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <img src="assets/images/popup-1.jpg">
  </div>
</ng-template>