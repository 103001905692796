<div class="function-bar search-bar">
  <button *ngIf="buttonText" class="mat-stroked-button btn" (click)="buttonClicked()">
    {{ buttonText }}
  </button>
    <app-glc-input
      class="input-search"
      [title]="'SEARCH'"
      [placeholder]="'Suchbegriff(e) eingeben'"
      [(value)]="inputSearch"
      [name]="'inputSearch'"
    ></app-glc-input>
</div>
