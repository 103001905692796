import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Common } from '../../services/common.service';
import { Configuration, TABLE_SETTINGS } from 'src/app/constants';
import { BuildingService } from 'src/app/services';
import { Router } from '@angular/router';
import { Building } from 'src/app/models';



@Component({
  selector: 'app-glc-building',
  templateUrl: './building.component.html',
  styleUrls: ['./building.component.scss']
})
export class BuildingComponent implements OnInit, AfterViewInit {
  tableData: any;

  buildingRoute = Configuration.ROUTE_PATH.BUILDINGS;
  isAdmin = false;
  multipleSelect = false;
  pageSize = Configuration.pageSize;
  pageOffset = 0;
  searchValue = '';
  pageDescription = '';
  sortValue = {
    active: '',
    direction: ''
  };

  fakeData = [{
    id: 1,
    image: '../../../assets/images/thumb-2.jpg',
    format_name: {
      title: 'Liegenschaft "Willie" Seebach',
      text: [
        'Glatttalstrasse 118 F',
        '8052 Zürich'
      ]
    },
    format_location: {
      title: 'Wohnungreferenz',
      text: ['Studio 1.4']
    },
    format_owner: {
      title: 'Mieter',
      text: ['Peter Meiers']
    },
    format_device: {
      title: 'Gerät(e)',
      text: [1]
    },
    format_service: {
      title: 'Serviceaufträg(e)',
      text: [1]
    },
    format_rem_id: {
      title: 'REM (ID)',
      text: ['9102833479057302']
    }
  }, {
    id: 2,
    image: '../../../assets/images/thumb-2.jpg',
    format_name: {
      title: 'Liegenschaft "Willie" Seebach',
      text: [
        'Glatttalstrasse 118 C',
        '8052 Zürich'
      ]
    },
    format_location: {
      title: 'Wohnungreferenz',
      text: ['Wohnung 2.5']
    },
    format_owner: {
      title: 'Mieter',
      text: ['Susan Reinart']
    },
    format_device: {
      title: 'Gerät(e)',
      text: [3]
    },
    format_service: {
      title: 'Serviceaufträg(e)',
      text: [2]
    },
    format_rem_id: {
      title: 'REM (ID)',
      text: ['234092380432382']
    }
  }, {
    id: 3,
    image: '../../../assets/images/thumb-2.jpg',
    format_name: {
      title: 'Liegenschaft "Willie" Seebach',
      text: [
        'Glatttalstrasse 118 F',
        '8052 Zürich'
      ]
    },
    format_location: {
      title: 'Wohnungreferenz',
      text: ['Attika B1']
    },
    format_owner: {
      title: 'Mieter',
      text: ['Frei']
    },
    format_device: {
      title: 'Gerät(e)',
      text: [4]
    },
    format_service: {
      title: 'Serviceaufträg(e)',
      text: ['Keine']
    },
    format_rem_id: {
      title: 'REM (ID)',
      text: ['123080938343433']
    }
  }, {
    id: 4,
    image: '../../../assets/images/thumb-1.jpg',
    format_name: {
      title: 'Letzi Style Wohnungen',
      text: [
        'Karstlernstrasse 12',
        '8048 Zürich'
      ]
    },
    format_location: {
      title: 'Wohnungreferenz',
      text: ['1.5 Zh Wohnung']
    },
    format_owner: {
      title: 'Mieter',
      text: ['Robert Park']
    },
    format_device: {
      title: 'Gerät(e)',
      text: [1]
    },
    format_service: {
      title: 'Serviceaufträg(e)',
      text: ['Keine']
    },
    format_rem_id: {
      title: 'REM (ID)',
      text: ['92837192129742']
    }
  }];

  constructor(
    private common: Common,
    private buildingService: BuildingService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.isAdmin = this.common.isAdmin();
    this.pageDescription = this.isAdmin ? 'BUILDING_DESCRIPTION' : '';
    this.initTableData();
  }

  initTableData() {
    const displayedColumns = ['image', 'format_name', 'format_location', 'format_owner', 'format_device', 'format_service', 'format_rem_id', 'customEvent'];

    const tableData = {
      displayedColumns,
      field: [
        { name: 'image', title: '', img: true, sortable: false },
        { name: 'format_name', title: 'Objekt', hasTitleFormat: true },
        { name: 'format_location', title: 'Wohnungreferenz', hasTitleFormat: true },
        { name: 'format_owner', title: 'Mieter', hasTitleFormat: true },
        { name: 'format_device', title: 'Gerät(e)', hasTitleFormat: true },
        { name: 'format_service', title: 'Serviceaufträg(e)', hasTitleFormat: true },
        { name: 'format_rem_id', title: 'REM (ID)', hasTitleFormat: true },
        { name: 'customEvent', title: 'Aktion', hasDropdownAction: true, sortable: false }
      ],
      pageSize: this.pageSize,
      totalRecords: 0,
      pageIndex: this.pageOffset,
      dataSource: []
    };

    this.tableData = tableData;
  }

  ngAfterViewInit() {
    this.getBuildingList();
  }

  getBuildingList() {
    const options = this.common.generateGetListParams({
      pageSize: this.pageSize,
      pageOffset: this.pageOffset,
      searchValue: this.searchValue,
      sortValue: this.sortValue
    });

    const response = this.fakeData;
    const buildings = [];
    for (let i = 0; i < response.length; i++) {
      const item: any = response[i];
      item.customEvent = {
        text: 'Optionen',
        icon: 'angle-down',
        screenshot: '../../../assets/images/action-4.jpg',
        event: () => {

        }
      };
      item.selectedId = item.id;
      buildings.push(item);
    }

    this.tableData = {
      ...this.tableData,
      pageIndex: this.pageOffset,
      totalRecords: response.length,
      dataSource: buildings
    };
  }

  applyFilter(_filterValue: string) {
    this.getBuildingList();
  }

  pageChange(event) {
    console.log(event);
    this.pageOffset = event.pageIndex;
    this.applyFilter(this.searchValue);
  }

  sortChange(event) {
    this.sortValue = event;
    this.getBuildingList();
  }

  createNewData(id: number) {
    return {
      id,
      image: '../../../assets/images/objektliste.png',
      format_name: {
        title: 'Liegenschaft "Willie" Seebach',
        text: [
          'Glatttalstrasse 118 F',
          '8052 Zürich'
        ]
      },
      format_location: {
        title: 'Wohnungreferenz',
        text: ['Studio 1.4']
      },
      format_owner: {
        title: 'Mieter',
        text: ['Peter Meiers']
      },
      format_device: {
        title: 'Gerät(e)',
        text: [1]
      },
      format_service: {
        title: 'Serviceaufträg(e)',
        text: [1]
      },
      format_rem_id: {
        title: 'REM (ID)',
        text: ['9102833479057302']
      }
    };
  }

  addNew() {
    this.router.navigate([`/${this.buildingRoute}/create`]);
  }

}
