
<app-glc-page-content [pageTitle]="'Geräte'">
  <app-glc-glsearch buttonText="Gerät hinzufügen" (buttonClick)="open(content)" [(inputSearch)]="searchValue" (search)="applyFilter($event)">
  </app-glc-glsearch>
  <span class="result-counter">Resultate (6)</span>
  <app-glc-table class="myTable table-5" [(data)]="tableData" (page)="pageChange($event)" (sort)="sortChange($event)">
  </app-glc-table>
</app-glc-page-content>

<ng-template #content let-modal>
  <div class="modal-header">
    <span class="modal-title" id="modal-basic-title">Gerät hinzufügen</span>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body modal-body-1">
    <img src="assets/images/popup-3.jpg">
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Abbrechen</button>
    <button class="btn-green">
      Hinzufügen
    </button>
  </div>
</ng-template>