<app-glc-page-content
  [pageTitle]="'Benutzerverwaltung'"
>
  <app-glc-glsearch buttonText="Benutzer hinzufügen" [(inputSearch)]="searchValue" (search)="applyFilter($event)"></app-glc-glsearch>
  <app-glc-table 
    class="myTable table-4"
    [(data)]="tableData"
    (page)="pageChange($event)" 
    (sort)="sortChange($event)"
  >
  </app-glc-table>
</app-glc-page-content>
