<div *ngIf="hasDelete && data.dataSource.length > 0" class="row delete-container">
  <div class="col-md-12">
    <a [hidden]="showMultipleSelect" class="pull-right" (click)="showMultipleSelect = !showMultipleSelect">
      <i class="icon link fas fa-trash-alt"></i>{{ 'DELETE_MULTIPLE_ENTRIES' | translate }}
    </a>
    <a *ngIf="showMultipleSelect" class="pull-right red" (click)="deleteMultipleItems()">
      <i class="icon red fas fa-trash-alt"></i>{{ 'DELETE_NUMBER_ITEMS' | translate:totalSelectedItem }}
    </a>
    <a *ngIf="showMultipleSelect" class="link pull-right pr-15" (click)="cancelDelete()">
      <i class="icon link fas fa-times"></i>{{ 'CANCEL' | translate }}
    </a>
  </div>
</div>
<div class="glTable-container mat-elevation-z8" *ngIf="data.dataSource.length > 0">
  <div class="table-container">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" [ngClass]="{'enableSelect': showMultipleSelect}">
      <ng-container *ngFor="let item of data.field" matColumnDef="{{item.name}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="item.sortable === false || item.name == 'selectedId' || item.name == 'action'" [ngClass]="{'action': item.link, 'multipleSelect no-right-border': item.name == 'selectedId'}">
          <span *ngIf="item.name != 'selectedId'">{{ item.title ? (item.title.toString() | translate) : item.title }}</span>
          <app-glc-checkbox
            *ngIf="showMultipleSelect && item.name == 'selectedId'"
            [name]="'multipleSelect'"
            [(value)]="multipleSelect"
            (modelChangeEvent)="toggleAll()"
          ></app-glc-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [ngClass]="{'action': item.link, 'multipleSelect': item.name == 'selectedId'}">             
          <span *ngIf="!item.customTranslateValue && item.link == undefined && item.img == undefined && item.name != 'selectedId' && !item.hasTitleFormat && !item.customAction && !item.hasDropdownAction" title="{{row[item.name] ? (row[item.name].toString() | translate) : row[item.name]}}" class="cell-content">
            {{ row[item.name] ? (row[item.name].toString() | translate) : row[item.name] }}
          </span>
          <span *ngIf="item.customTranslateValue">
            {{ row.customTranslateValue ? (row.customTranslateValue.text | translate:row.customTranslateValue.data) : row[item.name] }}
          </span>
          <span class="action-container" *ngIf="item.link">
            <ng-container *ngFor="let link of row.action">
              <span>
                <a *ngIf="!link.isFile"
                  routerLink="{{link.url}}"
                  [state]="row"
                >
                  <i class="action-icon fa fa-{{link.icon}}"></i>
                  <span class="action-text">
                    {{ link.text ? (link.text.toString() | translate) : link.text }}
                  </span>
                </a>
                <a *ngIf="link.isFile"
                  href="{{link.url}}" target="_blank"
                >
                  <i class="action-icon fa fa-{{link.icon}}"></i>
                  <span class="action-text">
                    {{ link.text ? (link.text.toString() | translate) : link.text }}
                  </span>
                </a>
              </span>
            </ng-container>
          </span>
          <img *ngIf="item.img" src="{{row[item.name]}}"/>
          <app-glc-checkbox
            *ngIf="showMultipleSelect && item.name == 'selectedId'"
            [name]="row[item.name]"
            [(value)]="row.selected"
            (modelChangeEvent)="checkStateAllSelect()"
            [isDisabled]="row.disabled"
          >
          </app-glc-checkbox>
          <div *ngIf="item.hasTitleFormat">
            <div [ngClass]="{'black-title': row[item.name].text.length > 1, 'green-title': row[item.name].text.length === 1 }">{{ row[item.name].title }}</div>
            <div class="content-text" *ngFor="let textData of row[item.name].text">
              {{ textData }}
            </div>
          </div>
          <div *ngIf="item.customAction">
            <span>
              <a (click)="row[item.name].event()">
                <span class="action-text">
                  {{ row[item.name].text ? (row[item.name].text.toString() | translate) : row[item.name].text }}
                </span>
                <i class="action-icon fa fa-{{row[item.name].icon}}"></i>
              </a>
            </span>
          </div>
          <div *ngIf="item.hasDropdownAction">
            <div class="dropdown">
              <button class="btn btn-default dropdown-toggle" type="button" id="{{'dropdownMenu_' + row.id}}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                {{ row[item.name].text ? (row[item.name].text.toString() | translate) : row[item.name].text }}
                <span class="caret"></span>
              </button>
              <ul class="dropdown-menu" attr.aria-labelledby="{{'dropdownMenu_' + row.id}}">
                <li><img [src]="row[item.name].screenshot" (click)="row[item.name].event()"></li>
              </ul>
            </div>
          </div>
        </mat-cell>
        <!-- <mat-cell *matCellDef="let row"><span></span></mat-cell> -->
      </ng-container>

      <!-- Definition for "other header" -->
      <ng-container matColumnDef="other-header">
        <mat-header-cell *matHeaderCellDef style="display: none"> 
        </mat-header-cell>
      </ng-container>
      
      <mat-header-row [ngClass]="{'hidden': !showHeader}" *matHeaderRowDef="data.displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: data.displayedColumns;">
      </mat-row>
    </mat-table>
  </div>
  <mat-paginator *ngIf="data.totalRecords > data.pageSize" length="{{data.totalRecords}}" pageSize="{{data.pageSize}}" pageIndex="{{data.pageIndex}}" showFirstLastButtons (page)="pageEvent = pageChangeEmit($event)"></mat-paginator>
</div>
<div class="no-record" *ngIf="data.dataSource.length == 0">
  <label>
    {{ 'NO_RECORD' | translate }}
  </label>
</div>
