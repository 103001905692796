<app-glc-page-content [pageTitle]="'Mieter'">
  <app-glc-glsearch buttonText="Mieter hinzufügen" (buttonClick)="open(content)" [(inputSearch)]="searchValue" (search)="applyFilter($event)">
  </app-glc-glsearch>
  <app-glc-table class="myTable table-2" [(data)]="tableData" (page)="pageChange($event)" (sort)="sortChange($event)">
  </app-glc-table>
</app-glc-page-content>

<ng-template #content let-modal>
  <div class="modal-header">
    <span class="modal-title" id="modal-basic-title">Mieter hinzufügen</span>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="modal-body modal-body-1">
    <form>
      <app-glc-input isRequired isHideRequireMarker="true" [title]="'NAME'" [placeholder]="'NAME'" [name]="'name'">
      </app-glc-input>
      <app-glc-input isRequired isHideRequireMarker="true" [title]="'E-Mail'" [placeholder]="'E-Mail'" [name]="'email'">
      </app-glc-input>
      <app-glc-input isRequired isHideRequireMarker="true" [title]="'Telefon'" [placeholder]="'Telefon'"
        [name]="'telefon'">
      </app-glc-input>
      <div class="telefon-des">Weitere Telefonnummer hinzufügen</div>
      <app-glc-select isRequired [title]="'Objekt (Nur freie Wohnungen werden angezeigt)'"
        [placeholder]="'PLEASE_SELECT'"></app-glc-select>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Abbrechen</button>
    <button class="btn-gray">
      Hinzufügen
    </button>
  </div>
</ng-template>