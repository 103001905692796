import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, LoginGuard, AdminGuard, UserGuard, CanDeactivateGuard, AdminSuperUserGuard } from './guards';
import {
  LoginComponent,
  PageNotFoundComponent,
  SettingsComponent,
  BuildingComponent,
  UsersComponent,
  UserDetailComponent,
  FunctionsComponent,
  FunctionDetailComponent,
  BuildingDetailComponent,
  EventCalendarComponent,
  ServiceTeamComponent,
  ServiceTeamDetailComponent,
  DevicesComponent,
  DeviceDetailComponent,
  DeviceDetailTabsComponent,
  InfoTabComponent,
  DocumentsTabComponent,
  ServiceTabComponent,
  ControlTabComponent,
  AlarmsTabComponent,
  TaskComponent,
  TaskDetailComponent,
  AlarmDetailComponent,
  DocumentDetailComponent,
  DeviceControlDetailComponent,
  AlarmComponent,
  ServiceDetailComponent
} from './screens';
import { MainComponent } from './components/main/main.component';
import { Configuration } from './constants';

const appRoute = Configuration.ROUTE_PATH;

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: `/${appRoute.BUILDINGS}`,
        pathMatch: 'full',
      },
      {
        path: appRoute.BUILDINGS,
        component: BuildingComponent,
      },
      {
        path: appRoute.SETTINGS,
        component: SettingsComponent,
      },
      {
        path: appRoute.USERS,
        component: UsersComponent,
      },
      {
        path: `${appRoute.USERS}/create`,
        component: UserDetailComponent,
      },
      {
        path: `${appRoute.USERS}/:id`,
        component: UserDetailComponent,
      },
      {
        path: `${appRoute.BUILDINGS}/create`,
        component: BuildingDetailComponent,
      },
      {
        path: `${appRoute.BUILDINGS}/:id`,
        component: BuildingDetailComponent,
      },
      {
        path: appRoute.FUNCTIONS,
        component: FunctionsComponent,
      },
      {
        path: `${appRoute.FUNCTIONS}/create`,
        component: FunctionDetailComponent,
      },
      {
        path: `${appRoute.FUNCTIONS}/:id`,
        component: FunctionDetailComponent,
      },
      {
        path: `${appRoute.DEVICES}/:id/:type/${appRoute.EVENT_CALENDAR}`,
        component: EventCalendarComponent,
      },
      {
        path: `${appRoute.DEVICES}`,
        component: DevicesComponent,
      },
      {
        path: `${appRoute.DEVICES}/function/:functionID`,
        component: DevicesComponent,
      },
      {
        path: `${appRoute.DEVICES}/internal-team/:internalID`,
        component: DevicesComponent,
      },
      {
        path: `${appRoute.DEVICES}/external-team/:externalID`,
        component: DevicesComponent,
      },
      {
        path: `${appRoute.DEVICES}/create`,
        component: DeviceDetailComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: `${appRoute.SERVICE_TEAMS}`,
        component: ServiceTeamComponent,
      },
      {
        path: `${appRoute.SERVICE_TEAMS}/create`,
        component: ServiceTeamDetailComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: `${appRoute.SERVICE_TEAMS}/:id`,
        component: ServiceTeamDetailComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: appRoute.ALARMS,
        component: AlarmComponent,
      },
      {
        path: `${appRoute.ALARMS}/:id`,
        component: AlarmDetailComponent,
      },
      {
        path: `${appRoute.DEVICES}/:id`,
        component: DeviceDetailTabsComponent,
        children: [
          {
            path: '',
            redirectTo: appRoute.DEVICE_INFO_TAB,
            pathMatch: 'full',
          },
          {
            path: appRoute.DEVICE_INFO_TAB,
            component: InfoTabComponent,
            canDeactivate: [CanDeactivateGuard]
          },
          {
            path: appRoute.DEVICE_DOCUMENTS_TAB,
            component: DocumentsTabComponent,
          },
          {
            path: appRoute.DEVICE_SERVICE_TAB,
            component: ServiceTabComponent,
          },
          {
            path: appRoute.DEVICE_CONTROL_TAB,
            component: ControlTabComponent,
          },
          {
            path: appRoute.DEVICE_ALARMS_TAB,
            component: AlarmsTabComponent,
          }
        ]
      },
      {
        path: appRoute.TO_DO_LIST,
        component: TaskComponent,
      },
      {
        path: `${appRoute.TO_DO_LIST}/create`,
        component: TaskDetailComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: `${appRoute.TO_DO_LIST}/:id`,
        component: TaskDetailComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: `${appRoute.DEVICES}/:deviceID/${appRoute.DEVICE_DOCUMENTS_TAB}/:documentID`,
        component: DocumentDetailComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: `${appRoute.DEVICES}/:deviceID/${appRoute.DEVICE_CONTROL_TAB}/:id`,
        component: DeviceControlDetailComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: `${appRoute.DEVICES}/:deviceID/${appRoute.DEVICE_CONTROL_TAB}/create`,
        component: DeviceControlDetailComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: `${appRoute.DEVICES}/:deviceID/${appRoute.DEVICE_SERVICE_TAB}/create`,
        component: ServiceDetailComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: `${appRoute.DEVICES}/:deviceID/${appRoute.DEVICE_SERVICE_TAB}/:serviceID`,
        component: ServiceDetailComponent,
        canDeactivate: [CanDeactivateGuard]
      }
    ]
  },
  {
    path: appRoute.LOGIN,
    component: LoginComponent,
  },
  {
    path: appRoute.PAGE_NOT_FOUND,
    component: PageNotFoundComponent
  },
  {
    path: '**',
    redirectTo: `/${appRoute.PAGE_NOT_FOUND}`,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
