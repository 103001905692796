import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Configuration, CONFIRM_MODAL_OPTIONS, TABLE_SETTINGS, TOASTER_TYPE } from 'src/app/constants';
import { Common } from 'src/app/services/common.service';
import { DeviceService } from 'src/app/services';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-glc-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {
  deviceRoute = Configuration.ROUTE_PATH.DEVICES;
  pageDescription = '';
  functionID: number;
  internalID: number;
  externalID: number;
  // Declare table settings
  isShowMultipleSelect = false;
  tableData: any = null;
  pageSize = Configuration.pageSize;
  pageOffset = 0;
  searchValue = '';
  sortValue = {
    active: '',
    direction: ''
  };

  fakeData = [{
    id: 1,
    image: '../../../assets/images/thumb-3.jpg',
    name: 'Steam Crisp',
    modelId: {
      title: 'Model ID',
      text: ['ELK10309BF']
    },
    serial: {
      title: 'Serial no.',
      text: ['1235489788']
    },
    pnc: {
      title: 'PNC',
      text: ['944271438']
    },
    address: {
      title: 'Adresse',
      text: ['Glatttalstrasse 118 C, 8052 Zürich']
    },
    wohnung: {
      title: 'Wohnungreferenz',
      text: ['Wohnung 2.5']
    },
    mieter: {
      title: 'Mieter',
      text: ['Susan Reinart']
    },
    service: {
      title: 'Serviceprovider',
      text: ['Electrolux']
    }
  }, {
    id: 2,
    image: '../../../assets/images/thumb-4.jpg',
    name: 'Einbau-Kühlgerät',
    modelId: {
      title: 'Model ID',
      text: ['IK28910SAL']
    },
    serial: {
      title: 'Serial no.',
      text: ['1235489788']
    },
    pnc: {
      title: 'PNC',
      text: ['944271438']
    },
    address: {
      title: 'Adresse',
      text: ['Glatttalstrasse 118 C, 8052 Zürich']
    },
    wohnung: {
      title: 'Wohnungreferenz',
      text: ['Wohnung 2.5']
    },
    mieter: {
      title: 'Mieter',
      text: ['Susan Reinart']
    },
    service: {
      title: 'Serviceprovider',
      text: ['REAP']
    }
  }, {
    id: 3,
    image: '../../../assets/images/thumb-5.jpg',
    name: 'Kochfelddunstabzug',
    modelId: {
      title: 'Model ID',
      text: ['DMGL8310SW']
    },
    serial: {
      title: 'Serial no.',
      text: ['1235489788']
    },
    pnc: {
      title: 'PNC',
      text: ['944271438']
    },
    address: {
      title: 'Adresse',
      text: ['Glatttalstrasse 118 C, 8052 Zürich']
    },
    wohnung: {
      title: 'Wohnungreferenz',
      text: ['Wohnung 2.5']
    },
    mieter: {
      title: 'Mieter',
      text: ['Susan Reinart']
    },
    service: {
      title: 'Serviceprovider',
      text: ['Electrolux']
    }
  }, {
    id: 4,
    image: '../../../assets/images/thumb-6.jpg',
    name: 'Geschirrspüler',
    modelId: {
      title: 'Model ID',
      text: ['GA421KLIP']
    },
    serial: {
      title: 'Serial no.',
      text: ['12345678']
    },
    pnc: {
      title: 'PNC',
      text: ['944271438']
    },
    address: {
      title: 'Adresse',
      text: ['Glatttalstrasse 118 F, 8052 Zürich']
    },
    wohnung: {
      title: 'Wohnungreferenz',
      text: ['Studio 1.4']
    },
    mieter: {
      title: 'Mieter',
      text: ['Peter Meiers']
    },
    service: {
      title: 'Serviceprovider',
      text: ['REAP']
    }
  }, {
    id: 5,
    image: '../../../assets/images/thumb-7.jpg',
    name: 'Heizung',
    modelId: {
      title: 'Model ID',
      text: ['BST219826']
    },
    serial: {
      title: 'Serial no.',
      text: ['--']
    },
    pnc: {
      title: 'PNC',
      text: ['--']
    },
    address: {
      title: 'Adresse',
      text: ['Karstlernstrasse 12, 8048  Zürich']
    },
    wohnung: {
      title: 'Wohnungreferenz',
      text: ['Attika B1']
    },
    mieter: {
      title: 'Mieter',
      text: ['Robert Park']
    },
    service: {
      title: 'Serviceprovider',
      text: ['REAP']
    }
  }, {
    id: 6,
    image: '../../../assets/images/thumb-8.jpg',
    name: 'Storren',
    modelId: {
      title: 'Model ID',
      text: ['OPTZUSAK']
    },
    serial: {
      title: 'Serial no.',
      text: ['12345678']
    },
    pnc: {
      title: 'PNC',
      text: ['944271438']
    },
    address: {
      title: 'Adresse',
      text: ['Glatttalstrasse 118 F, 8052 Zürich']
    },
    wohnung: {
      title: 'Wohnungreferenz',
      text: ['Studio 1.4']
    },
    mieter: {
      title: 'Mieter',
      text: ['Peter Meiers']
    },
    service: {
      title: 'Serviceprovider',
      text: ['Electrolux']
    }
  }];

  @ViewChild('content', { static: false }) content: ElementRef;

  constructor(
    private common: Common,
    private deviceService: DeviceService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  open(content) {
    this.modalService.open(content, { windowClass: 'custom-modal-size-1' }).result.then((result) => {
      console.log(result);
    }, (reason) => {
      console.log(reason);
    });
  }

  ngOnInit() {
    this.initTable();
    this.getDeviceList();
  }

  getDeviceList() {
    const response = this.fakeData;
    const buildings = [];
    for (let i = 0; i < response.length; i++) {
      const item: any = response[i];
      item.customEvent = {
        text: 'Optionen',
        icon: 'angle-down',
        screenshot: '../../../assets/images/action-2.jpg',
        event: () => {
        }
      };
      item.selectedId = item.id;
      buildings.push(item);
    }

    this.tableData = {
      ...this.tableData,
      pageIndex: this.pageOffset,
      totalRecords: response.length,
      dataSource: buildings
    };
  }

  initTable() {
    const displayedColumns = [
      'image', 'name', 'modelId', 'serial', 'pnc', 'address', 'wohnung', 'mieter', 'service', 'customEvent'];

    this.tableData = {
      displayedColumns,
      field: [
        { name: 'image', title: '', img: true, sortable: false },
        {
          name: 'name',
          title: 'Gerät'
        },
        {
          name: 'modelId',
          title: 'Model ID', hasTitleFormat: true
        },
        {
          name: 'serial',
          title: 'Serial no.', hasTitleFormat: true
        },
        {
          name: 'pnc',
          title: 'PNC', hasTitleFormat: true
        },
        {
          name: 'address',
          title: 'Adresse', hasTitleFormat: true
        },
        {
          name: 'wohnung',
          title: 'Wohnungreferenz', hasTitleFormat: true
        },
        {
          name: 'mieter',
          title: 'Mieter', hasTitleFormat: true
        },
        {
          name: 'service',
          title: 'Serviceprovider', hasTitleFormat: true
        },
        { name: 'customEvent', title: 'Aktion', hasDropdownAction: true, sortable: false }
      ],
      pageSize: this.pageSize,
      totalRecords: 0,
      pageIndex: this.pageOffset,
      dataSource: []
    };
  }

  createNewData(id: number) {
    return {
      id,
      image: '../../../assets/images/objektliste.png',
      name: 'Steam Crisp',
      modelId: {
        title: 'Model ID',
        text: ['ELK10309BF']
      },
      serial: {
        title: 'Serial no.',
        text: ['123456789']
      },
      pnc: {
        title: 'PNC',
        text: ['123456789']
      },
      address: {
        title: 'Adresse',
        text: ['Glatttalstrasse 118 F, 8052 Zürich']
      },
      wohnung: {
        title: 'Wohnungreferenz',
        text: ['Wohnung 2.5']
      },
      mieter: {
        title: 'Mieter',
        text: ['Susan Reinart']
      },
      service: {
        title: 'Serviceprovider',
        text: ['Electrolux']
      }
    };
  }

  applyFilter(_filterValue: string) {
    this.getDeviceList();
  }

  pageChange(event) {
    this.pageOffset = event.pageIndex;
    this.applyFilter(this.searchValue);
  }

  sortChange(event) {
    this.sortValue = event;
    switch (event.active) {
      case 'building_name':
        this.sortValue.active = 'building__name';
        break;
      case 'function_name':
        this.sortValue.active = 'function__name';
        break;
    }
    this.getDeviceList();
  }

  add() {
  }

  deleteDevices(listIds) {
    this.common.showConfirm(
      this.common.translate('CONFIRM'),
      this.common.translate('CONFIRM_DELETE_DEVICE'),
      () => {
        try {
          this.deviceService.delete({
            ids: listIds
          })
            .subscribe(
              (response: any) => {
                this.common.showToaster(
                  TOASTER_TYPE.success,
                  this.common.translate('SUCCESS'),
                  this.common.translate('DELETE_SUCCESSFUL')
                );
                this.isShowMultipleSelect = false;
                this.getDeviceList();
              });
        } catch (error) {
          this.common.hideLoader();
          this.common.showError([JSON.stringify(error)]);
        }
      }, null, CONFIRM_MODAL_OPTIONS.TYPE.RED
    );
  }
}
