import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Common } from '../../services/common.service';
import { Configuration, TABLE_SETTINGS, CONFIRM_MODAL_OPTIONS, TOASTER_TYPE } from 'src/app/constants';
import { ServiceTeamService } from 'src/app/services';
import { Router } from '@angular/router';
import { ServiceTeam } from 'src/app/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { ExportToCsv } from 'export-to-csv';

@Component({
  selector: 'app-glc-service-team',
  templateUrl: './service-team.component.html',
  styleUrls: ['./service-team.component.scss']
})
export class ServiceTeamComponent implements OnInit, AfterViewInit {
  tableData: any;

  serviceTeamRoute = Configuration.ROUTE_PATH.SERVICE_TEAMS;
  deviceRoute = Configuration.ROUTE_PATH.DEVICES;
  isAdmin = false;
  multipleSelect = false;
  pageSize = Configuration.pageSize;
  pageOffset = 0;
  searchValue = '';
  pageDescription = '';
  sortValue = {
    active: '',
    direction: ''
  };

  fakeData = [{
    id: 1,
    image: '../../../assets/images/thumb-1.png',
    name: {
      title: 'Steam Crisp funktioniert nicht',
      text: [
        'Adresse',
        'Glatttalstrasse 118 F, 8052 Zürich'
      ]
    },
    wohnung: {
      title: 'Wohnungreferenz',
      text: ['Studio 1.4']
    },
    mieter: {
      title: 'Mieter',
      text: ['Peter Meiers']
    },
    status: {
      title: 'Status',
      text: ['Manuelle Eingabe erforderlich!']
    },
    repa: {
      title: 'Reparaturkosten',
      text: ['CHF 1298.-']
    },
    service: {
      title: 'Serviceprovider',
      text: ['Electrolux']
    }
  }, {
    id: 2,
    image: '../../../assets/images/thumb-1.png',
    name: {
      title: 'Steam Crisp schlatet nicht mehr ein',
      text: [
        'Adresse',
        'Glatttalstrasse 118 F, 8052 Zürich'
      ]
    },
    wohnung: {
      title: 'Wohnungreferenz',
      text: ['Wohnung 2.5']
    },
    mieter: {
      title: 'Mieter',
      text: ['Susan Reinart']
    },
    status: {
      title: 'Status',
      text: ['Serviceauftrag wird durchgeführt']
    },
    repa: {
      title: 'Reparaturkosten',
      text: ['CHF 230.-']
    },
    service: {
      title: 'Serviceprovider',
      text: ['REAP']
    }
  }, {
    id: 3,
    image: '../../../assets/images/thumb-4.jpg',
    name: {
      title: 'Kühlschrank schliesst nicht vollständig',
      text: [
        'Adresse',
        'Glatttalstrasse 118 F, 8052 Zürich'
      ]
    },
    wohnung: {
      title: 'Wohnungreferenz',
      text: ['Wohnung 2.5']
    },
    mieter: {
      title: 'Mieter',
      text: ['Susan Reinart']
    },
    status: {
      title: 'Status',
      text: ['Serviceauftrag wird durchgeführt']
    },
    repa: {
      title: 'Reparaturkosten',
      text: ['CHF 128.-']
    },
    service: {
      title: 'Serviceprovider',
      text: ['Electrolux']
    }
  }];

  @ViewChild('content', { static: false }) content: ElementRef;

  constructor(
    private common: Common,
    private teamService: ServiceTeamService,
    private router: Router,
    private modalService: NgbModal
  ) {
  }

  open(content) {
    this.modalService.open(content, { size: 'lg' }).result.then((result) => {
      console.log(result);
    }, (reason) => {
      console.log(reason);
    });
  }

  ngOnInit() {
    this.initTableData();
  }

  initTableData() {
    const displayedColumns = [
      'image', 'name', 'wohnung', 'mieter', 'status', 'repa', 'service', 'customEvent'];

    this.tableData = {
      displayedColumns,
      field: [
        { name: 'image', title: '', img: true, sortable: false },
        {
          name: 'name',
          title: 'Serviceauftrag', hasTitleFormat: true
        },
        {
          name: 'wohnung',
          title: 'Wohnungreferenz', hasTitleFormat: true
        },
        {
          name: 'mieter',
          title: 'Mieter', hasTitleFormat: true
        },
        {
          name: 'status',
          title: 'Status', hasTitleFormat: true
        },
        {
          name: 'repa',
          title: 'Reparaturkosten', hasTitleFormat: true
        },
        {
          name: 'service',
          title: 'Serviceprovider', hasTitleFormat: true
        },
        { name: 'customEvent', title: 'Aktion', hasDropdownAction: true, sortable: false }
      ],
      pageSize: this.pageSize,
      totalRecords: 0,
      pageIndex: this.pageOffset,
      dataSource: []
    };
  }

  ngAfterViewInit() {
    this.getServiceTeamList();
  }

  getServiceTeamList() {
    const response = this.fakeData;
    const buildings = [];
    for (let i = 0; i < response.length; i++) {
      const item: any = response[i];
      item.customEvent = {
        text: 'Optionen',
        icon: 'angle-down',
        screenshot: '../../../assets/images/action-1.jpg',
        event: () => {
          this.open(this.content);
        }
      };
      item.selectedId = item.id;
      buildings.push(item);
    }

    this.tableData = {
      ...this.tableData,
      pageIndex: this.pageOffset,
      totalRecords: response.length,
      dataSource: buildings
    };
  }

  createNewData(id: number) {
    return {
      id,
      image: '../../../assets/images/thumb-1.png',
      name: {
        title: 'Steam Crisp funktioniert nicht',
        text: [
          'Adresse',
          'Glatttalstrasse 118 F, 8052 Zürich'
        ]
      },
      wohnung: {
        title: 'Wohnungreferenz',
        text: ['Wohnung 2.5']
      },
      mieter: {
        title: 'Mieter',
        text: ['Susan Reinart']
      },
      status: {
        title: 'Status',
        text: ['Manuelle Eingabe erforderlich!']
      },
      repa: {
        title: 'Reparaturkosten',
        text: ['CHF 1298.-']
      },
      service: {
        title: 'Serviceprovider',
        text: ['Electrolux']
      }
    };
  }

  applyFilter(_filterValue: string) {
    this.getServiceTeamList();
  }

  pageChange(event) {
    console.log(event);
    this.pageOffset = event.pageIndex;
    this.applyFilter(this.searchValue);
  }

  sortChange(event) {
    this.sortValue = event;
    switch (event.active) {
      case 'teamType':
        this.sortValue.active = 'in_house';
        break;
    }
    this.getServiceTeamList();
  }

  addNew() {
    this.router.navigate([`/${this.serviceTeamRoute}/create`]);
  }

  deleteDevices(listIds) {
    this.common.showConfirm(
      this.common.translate('CONFIRM'),
      this.common.translate('SERVICE_TEAM_CONFIRM_DELETE'),
      () => {
        try {
          this.teamService.delete({
            ids: listIds
          })
            .subscribe(
              (response: any) => {
                console.log(response);
                this.common.showToaster(
                  TOASTER_TYPE.success,
                  this.common.translate('SUCCESS'),
                  this.common.translate('DELETE_SUCCESSFUL')
                );
                this.multipleSelect = false;
                this.getServiceTeamList();
              });
        } catch (error) {
          this.common.hideLoader();
          this.common.showError([JSON.stringify(error)]);
        }
      }, null, CONFIRM_MODAL_OPTIONS.TYPE.RED
    );
  }

  getExportData() {
    if (this.tableData.dataSource.length > 0) {
      try {
        this.teamService.getExportData(this.searchValue)
          .subscribe(
            (response: any) => {
              console.log(response);
              this.common.hideLoader();
            });
      } catch (error) {
        this.common.hideLoader();
        this.common.showError([JSON.stringify(error)]);
      }
    }
  }
}
